import './footer.css';

const Footer = () => {

  return (
    <footer>
      <div className="footer">
        <div className="footerElement">
          Fun fact : I have feet.
        </div>
      </div>
    </footer>
  )
}

export default Footer;