import './notFound.css';


const NotFound = () => {

  const kerrigan = require('../../Img/Kerrigan.jpeg');

  return (
    <div className="notFoundContainer">
      <div className='funDiv'>That page cannot be found. </div>
      <img src={kerrigan} alt="kerrigan" className="kerrigan" />
    </div>
  )
}

export default NotFound;