import './IconContainer.css';


const IconContainer = (props) => {

  const {imgSrc, text} = props;

  return (
    <div className="iconContainer">
      <img className='skillsIcon' src={imgSrc} alt={text} title={text}  />
      <span>{text}</span>
    </div>
  )
};

export default IconContainer;