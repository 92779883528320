import './menu.css';
import { Link } from 'react-router-dom';

const Menu = () => {

  return(
    <header className="menu">
      <div className="menuContainer">
        <div className="menuLeft">
          <Link to='/'>
            <span className="menuItem home">Danek Fill</span>
          </Link>
        </div>
        <div className="menuRight">
          <Link to='/contact'>
            <span className="menuItem">Contact Me</span>
          </Link>
          {/* <Link to='/about'>
            <span className="menuItem">About Me</span>
          </Link> */}
        </div>
      </div>
    </header>
  )
}

export default Menu;