import './Skills.css';
import { useState, useEffect } from 'react';
import IconContainer from './iconContainer/IconContainer';

//images
/*
IMAGE SOURCE :
https://github.com/devicons/devicon
*/
//Frontend
import javascriptImg from '../../Img/stackImages/javascript-original.svg';
import tsImage from '../../Img/stackImages/typescript-original.svg';
import reactImg from '../../Img/stackImages/react-original.svg';
import jQueryImg from '../../Img/stackImages/jquery-original.svg';

import djangoImg from '../../Img/stackImages/django-plain.svg';

//Backend
import expressImg from '../../Img/stackImages/express-original.svg';
import mongoDbImg from '../../Img/stackImages/mongodb-original.svg';
import psqlImg from '../../Img/stackImages/postgresql-original.svg';


//Testing
import mochaImg from '../../Img/stackImages/mocha-original.svg';
import jestImg from '../../Img/stackImages/jest-plain.svg';

//Other
import nodeJsImg from  '../../Img/stackImages/nodejs-original.svg';
import gitImg from '../../Img/stackImages/github-original.svg';
import figmaImg from '../../Img/stackImages/figma-original.svg';

//Misc
import slashImg from '../../Img/stackImages/slash.svg';

const frontendStack = (
  <>
    <IconContainer imgSrc={javascriptImg} text='Javascript' />
    <IconContainer imgSrc={tsImage} text='Typescript' />
    <IconContainer imgSrc={reactImg} text='React' />
    <IconContainer imgSrc={jQueryImg} text='jQuery' />

    <IconContainer imgSrc={djangoImg} text='Django' />
  </>
);
const backendStack = (
  <>
    <IconContainer imgSrc={expressImg} text='Express' />
    <IconContainer imgSrc={mongoDbImg} text='MongoDB' />
    <IconContainer imgSrc={psqlImg} text='PostgreSQL' />
  </>
);
const testingStack = (
  <>
    <IconContainer imgSrc={mochaImg} text='Mocha' />
    <IconContainer imgSrc={jestImg} text='Jest' />
  </>
);
const otherStack = (
  <>
    <IconContainer imgSrc={gitImg} text='Github' />
    <IconContainer imgSrc={nodeJsImg} text='NodeJs' />
    <IconContainer imgSrc={figmaImg} text='Figma' />
  </>
)


const Skills = () => {
  const [showSkills, setShowSkills] = useState({
    'frontend': 'active',
    'backend': 'inactive',
    'testing': 'inactive',
    'other' : 'inactive',
  });
  const activateFrontend = () => {
    setShowSkills({
      'frontend': 'active',
      'backend': 'inactive',
      'testing': 'inactive',
      'other' : 'inactive',
    })
  };
  const activateBackend = () => {
    setShowSkills({
      'frontend': 'inactive',
      'backend': 'active',
      'testing': 'inactive',
      'other' : 'inactive',
    })
  };
  const activateTesting = () => {
    setShowSkills({
      'frontend': 'inactive',
      'backend': 'inactive',
      'testing': 'active',
      'other' : 'inactive',
    })
  };
  const activateOther = () => {
    setShowSkills({
      'frontend': 'inactive',
      'backend': 'inactive',
      'testing': 'inactive',
      'other' : 'active',
    })
  }
  


  return (
    <>
      <h2>My preferred stack/tools :</h2>

      <div className="buttons">
        <button className={`button ${showSkills.frontend}`} onClick={activateFrontend}>Frontend</button>
        <button className={`button ${showSkills.backend}`} onClick={activateBackend}>Backend</button>
        <button className={`button ${showSkills.testing}`} onClick={activateTesting}>Testing</button>
        <button className={`button ${showSkills.other}`} onClick={activateOther}>Other</button>
       </div>

        
                 
      <ul className='stackList'>
        <div id='frontend' className={`list + ${showSkills.frontend}`} >
          {frontendStack}
        </div>
        <div id='backend' className={`list + ${showSkills.backend}`}>
          {backendStack}
        </div>
        <div id='testing' className={`list + ${showSkills.testing}`}>
          {testingStack}
        </div>
        <div id='other' className={`list + ${showSkills.other}`}>
          {otherStack}
        </div>


      </ul>
    </>
  );
}

export default Skills;