import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//Public Pages Imports
import Menu from './Components/Menu/Menu';
import Home from './Pages/Home/Home';
import Contact from './Pages/Contact/Contact';
import About from './Pages/About/About';

import Footer from './Components/Footer/Footer';
import NotFound from './Pages/Not Found/NotFound';

//Private pages Imports

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Menu />
        <div className="pages">
          <Routes>
            {/* Public Routes */}
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<Contact />} />
            {/* <Route path='/about' element={<About />} /> */}

            {/* Private Routes */}


            <Route path='*' element={<NotFound />} />
          </Routes>        
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
