import './HomeLeft.css';
import Skills from '../../../Components/Skills/Skills';

const HomeLeft = () => {

  return (
    <>
      <h1 className='helloBanner'>Hello, <br/> I'm Danek.</h1>
      <span className='infoSpan'>A full stack web developper. With vast experience in requirement gathering, leadership, and product development.</span>
      <Skills />
    {/* <a href='/about'>Learn More</a> */}
  </>
  );
};

export default HomeLeft;