import { Link } from 'react-router-dom';
import './HomeRight.css';

const HomeRight = () => {

  const headshot = require('../../../Img/fullShot.jpg');

  return (
    <>
      <img className="headshot" src={headshot}/>
      <div className="otherInfo">
        <Link to='/contact'>
          <span className='contact'>Get in touch</span>
        </Link>
      </div>
    </>
  );
};

export default HomeRight;