import './contact.css';

const Contact = () => {

  return (
    <div className="container">
      <h1>Contact Me</h1>

      <div className="socialsFlexBox">
        <a href='https://www.linkedin.com/in/danek-fill/'>
          <div className="socialsItem linkedIn"><i class="fa-brands fa-linkedin fa-2x"></i></div>
        </a>
        <a href='https://github.com/danekf'>
          <div className="socialsItem github"><i class="fa-brands fa-github fa-2x"></i></div>
        </a>
        <a href='mailto:danek.fill@outlook.com?subject=Web%20Development%20Inquiry'>
          <div className="socialsItem email"><i class="fa-solid fa-envelope fa-2x"></i></div>
        </a>
        
      </div>
    </div>
  )
}

export default Contact;

