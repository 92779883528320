import './home.css';

import HomeLeft from './HomeLeft/HomeLeft';
import HomeRight from './HomeRight/HomeRight';



//TODO update skills to be inspired by the skill section https://rinada.vercel.app/

const Home = () => {
  return (
    <div className="homeContainer">
      <div className="homeLeft">
        <HomeLeft />
      </div>

      <div className="homeRight">
        <HomeRight />
      </div>

    </div>
  )
}

export default Home;